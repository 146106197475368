import { MessageType } from '@/models/message-type';
import { ChatSessionRecord } from '@/models/session-chat-record';
import { SessionDetail } from '@/models/session-detail';
import { SessionInfo } from '@/models/session-info';
import { SessionMessage } from '@/models/session-message';
import * as userSessionRepo from '@/repositories/user-session-repo';
import {gql} from '@apollo/client';
import * as luxon from 'luxon';
import {v4 as uuidv4} from 'uuid';
import {getAPIClient, getWSClient} from './graphql';

export type NotifyPromptResponse = {
  data: {
    notifyPromptResponse: {
      complete: boolean;
      id: string;
      output: string;
    };
  };
};

export const newSessionDetail = async (
  info: SessionInfo | SessionDetail | null = null
): Promise<SessionDetail> => {
  if (!info) {
    return Promise.resolve({
      id: uuidv4(),
      userId: userSessionRepo.getUsername()!,
      name: 'My First Conversation',
      createdOn: luxon.DateTime.now(),
      lastUpdated: luxon.DateTime.now(),
      systemMessages: 'You are a helpful AI assistant.',
      messages: [
        // {
        //   datestamp: luxon.DateTime.fromFormat('12/7/2023, 3:00 PM', 'f'),
        //   type: MessageType.Human,
        //   text: 'This is a sample human message.',
        //   metadata: {isComplete: true},
        // },
        // {
        //   datestamp: luxon.DateTime.fromFormat('12/7/2023, 3:01 PM', 'f'),
        //   type: MessageType.AI,
        //   text: "Hey there sample human!  I'm a sample AI.",
        //   metadata: {isComplete: true},
        // },
      ],
    });
  } else {
    return Promise.resolve({
      systemMessages: 'You are a helpful AI assistant.',
      messages: [
        // {
        //   datestamp: luxon.DateTime.fromFormat('12/7/2023, 3:00 PM', 'f'),
        //   type: MessageType.Human,
        //   text: 'This is a sample human message.',
        //   metadata: {isComplete: true},
        // },
        // {
        //   datestamp: luxon.DateTime.fromFormat('12/7/2023, 3:01 PM', 'f'),
        //   type: MessageType.AI,
        //   text: "Hey there sample human!  I'm a sample AI.",
        //   metadata: {isComplete: true},
        // },
      ],
      ...info,
    });
  }
};

/**
 * Get a list of session summaries.
 *
 * @returns A list of session summaries.
 */
export const getSessions = async (): Promise<SessionInfo[]> => {
  const client = getAPIClient();

  const results = await client.query({
    query: gql`
      query GetAllChatSummaries($userId: ID!) {
        getAllChatSummaries(userId: $userId) {
          id
          name
          userId
          createdOn
          lastUpdated
        }
      }
    `,
    variables: {
      userId: userSessionRepo.getUsername(),
    },
  });

  return results.data.getAllChatSummaries.map((x: any) => {
    return {
      id: x.id,
      userId: x.userId,
      name: x.name,
      createdOn: luxon.DateTime.fromISO(x.createdOn),
      lastUpdated: luxon.DateTime.fromISO(x.lastUpdated),
    }
  });
};

export const submitPrompt = async (
  id: string,
  prompt: string,
  modelId = 'ClaudeV2'
): Promise<ChatSessionRecord> => {
  const client = getAPIClient();
  const promptId = uuidv4();

  const results = await client.mutate({
    mutation: gql`
      mutation SubmitPrompt(
        $id: ID!
        $userId: ID!
        $promptId: ID!
        $prompt: String!
        $modelId: String!
      ) {
        submitPrompt(
          id: $id
          userId: $userId
          promptId: $promptId
          prompt: $prompt
          modelId: $modelId
        )
      }
    `,
    variables: {
      id,
      userId: userSessionRepo.getUsername(),
      promptId: promptId,
      prompt,
      modelId,
    },
  });

  return results.data.submitPrompt;
};

export const submitFeedback = async (
  id: string,
  recordId: string,
  feedback: string,
  score: number
): Promise<any> => {
  const client = getAPIClient();

  const results = await client.mutate({
    mutation: gql`
      mutation UpdateChatRecord(
        $id: ID!
        $userId: ID!
        $recordId: ID!
        $feedback: String
        $score: Int
      ) {
        updateChatRecord(
          id: $id
          userId: $userId
          recordId: $promptId
          feedback: $feedback
          score: $score
        )
      }
    `,
    variables: {
      id,
      userId: userSessionRepo.getUsername(),
      recordId,
      feedback,
      score,
    },
  });

  return results.data.createChat;
};

export const createSession = async (name: string): Promise<SessionInfo> => {
  const client = getAPIClient();

  const results = await client.mutate({
    mutation: gql`
      mutation CreateChat($userId: ID!, $name: String!) {
        createChat(name: $name, userId: $userId) {
          id
          name
          userId
          createdOn
          lastUpdated
        }
      }
    `,
    variables: {
      userId: userSessionRepo.getUsername(),
      name,
    },
  });

  return results.data.createChat;
};

export const deleteSession = async (id: string) => {
  const client = getAPIClient();

  await client.mutate({
    mutation: gql`
      mutation DeleteChat($userId: ID!, $id: ID!) {
        deleteChat(id: $id, userId: $userId)
      }
    `,
    variables: {
      userId: userSessionRepo.getUsername(),
      id,
    },
  });
};

export const deleteAllSessions = async () => {
  const client = getAPIClient();

  await client.mutate({
    mutation: gql`
      mutation DeleteChatsByUser($userId: ID!) {
        deleteChatsByUser(userId: $userId)
      }
    `,
    variables: {
      userId: userSessionRepo.getUsername(),
    },
  });
};

export const subscribeToChat = (
  id: string,
  onNext: (nxt: NotifyPromptResponse) => void,
  onError: (err: Error) => void,
  onComplete: () => void
) => {
  const client = getWSClient();

  const query = gql`
    subscription NotifyPromptResponse($id: ID!) {
      notifyPromptResponse(id: $id) {
        complete
        id
        output
      }
    }
  `;

  const observe = client.subscribe({
    query,
    variables: {
      id,
    },
  });

  return observe.subscribe(onNext, onError, onComplete);
};

export const getChatSummary = async (id: string): Promise<SessionInfo> => {
  const client = getAPIClient();

  const results = await client.query({
    query: gql`
      query GetChatSummary($id: ID!, $userId: ID!) {
        getChatSummary(id: $id, userId: $userId) {
          id
          userId
          name
          createdOn
          lastUpdated
        }
      }
    `,
    variables: {
      id: id,
      userId: userSessionRepo.getUsername(),
    },
  });

  const summary = results.data.getChatSummary;
  console.log('Summary: ', summary);
  
  return {
    id: summary.id,
    userId: summary.userId,
    name: summary.name,
    createdOn: luxon.DateTime.fromISO(summary.createdOn),
    lastUpdated: luxon.DateTime.fromISO(summary.lastUpdated),
  };
}

type DTOMessage = {
  // id: string,
  recordId: string,
  userId: string,
  prompt: string,
  generatedOutput: string,
  feedback: string,
  createdOn: string,
  lastUpdated: string,
  fileKeys: [],
}

const collectMessages = (records: DTOMessage[]): SessionMessage[] => {
  let messages: SessionMessage[] = [];

  for (let n = 0; n < records.length; n++) {
    let record = records[n];
    messages.push({
      datestamp: luxon.DateTime.fromISO(record.createdOn),
      type: MessageType.Human,
      text: record.prompt,
      metadata: {
        id: record.recordId,
        isComplete: true,
      }
    });
    messages.push({
      datestamp: luxon.DateTime.fromISO(record.createdOn),
      type: MessageType.AI,
      text: record.generatedOutput,
      metadata: {
        id: record.recordId,
        isComplete: true,
      }
    });
  }

  messages.sort((a, b) => a.datestamp.toMillis() - b.datestamp.toMillis());

  return messages;
}

/**
 * Get the details for a particular session.
 *
 * @param id
 * @returns
 */
export const getSession = async (id: string): Promise<SessionDetail> => {
  const summary = await getChatSummary(id);

  const client = getAPIClient();

  const results = await client.query({
    query: gql`
      query GetChatRecords($id: ID!, $userId: ID!) {
        getChatRecords(id: $id, userId: $userId) {
          recordId
          userId
          createdOn
          prompt
          feedback
          generatedOutput
          fileKeys
        }
      }
    `,
    variables: {
      id: id,
      userId: userSessionRepo.getUsername(),
    },
  });

  if (results.errors) {
    let errorText = results.errors.map((x: any) => x.message).join('\n');
    if (errorText.includes("type mismatch error, expected type LIST")) {
      errorText = "Chat record does not exist."; 
    }
    throw new Error("Error running query: " + errorText);
  }

  console.log('Results: ', results); //.data.getChatRecords);

  let details = {
    id: summary.id,
    userId: summary.userId,
    name: summary.name,
    createdOn: summary.createdOn,
    lastUpdated: summary.lastUpdated,
    systemMessages: '',
    messages: collectMessages(results.data.getChatRecords),
  };

  console.log('Details: ', details);

  return details;
};

export const renameSession = async (id: string, name: string): Promise<boolean> => {
  const client = getAPIClient();

  const results = await client.mutate({
    mutation: gql`
      mutation UpdateChat($id: ID!, $userId: ID!, $name: String!) {
        updateChat(id: $id, userId: $userId, name: $name) {
          id
          userId
          name
          createdOn
          lastUpdated
        }
      }
    `,
    variables: {
      id: id,
      userId: userSessionRepo.getUsername(),
      name: name,
    },
  });

  if (results.errors) {
    let errorText = results.errors.map((x: any) => x.message).join('\n');
    throw new Error("Error running query: " + errorText);
  }

  if (results.data.updateChat.name === name) {
    return true;
  }

  console.log('Unexpected name in results: ', results);
  return false;
}