import parseJwt from '@/services/jwt';

const SESSION_KEY = 'user-session';

export type UserSession = {
  accessToken: string;
  refreshToken: string;
  idToken: string;
  expiresAt: number;
  tokenType: string;
  userDetails: {
    email: string;
    name: string;
    'cognito:username': string;
  };
};

const hasSession = () => localStorage.getItem(SESSION_KEY);

export const getSession = (): UserSession | undefined =>
  hasSession() ? JSON.parse(localStorage.getItem(SESSION_KEY)!) : undefined;

const setSession = (session: UserSession) =>
  localStorage.setItem(SESSION_KEY, JSON.stringify(session));

export const getAccessToken = () => getSession()?.accessToken;

export const getUserDetails = () => getSession()?.userDetails;

export const getUserInitials = () =>
  getUserDetails()
    ?.name.split(' ')
    .map(x => x[0])
    .join('');

export const getUsername = () => getUserDetails()?.['cognito:username'];

export const clear = () => localStorage.removeItem(SESSION_KEY);

export const isLoggedIn = () => {
  if (!hasSession()) {
    console.log('You do not have a session.');
    return false;
  }
  if (getSession()!.expiresAt < Date.now()) {
    console.log(
      `Your session is expired: ${getSession()!.expiresAt} >= ${Date.now()}`
    );
    return false;
  }
  if (!getSession()?.accessToken) {
    console.log('You do not have an access token.');
    return false;
  }
  return (
    hasSession() &&
    getSession()!.expiresAt >= Date.now() &&
    getSession()?.accessToken
  );
};

export const logIn = (data: {
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_in: number;
  token_type: string;
}) => {
  clear();
  setSession({
    accessToken: data?.access_token,
    refreshToken: data?.refresh_token,
    idToken: data?.id_token,
    expiresAt: Date.now() + (data?.expires_in ?? 0) * 1000,
    tokenType: data?.token_type,
    userDetails: parseJwt(data?.id_token ?? ''),
  });
};

export const logOut = () => clear();
