'use client';

import Loader from '@/components/loader';
import * as userSessionRepo from '@/repositories/user-session-repo';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as chatSessionRepo from '@/repositories/chat-session-repo';

// function SafeHydrate(props: { children: any }) {
//   return <div suppressHydrationWarning>
//     {typeof window === 'undefined' ? null : props.children}
//   </div>
// }

export default function Home() {
  // TODO: TECH DEBT: This will generate a lot of empty conversations if you keep hitting Refresh.
  // By default, the app will generate a new conversation to kick things off.
  const router = useRouter();

  // console.log(window.location.origin);

  useEffect(() => {
    if (userSessionRepo.isLoggedIn()) {
      const sessions = chatSessionRepo.getSessions().then(sessions => {
        console.log('Preloading sessions:', sessions);
        if (sessions?.length > 0) {
          sessions.sort((a, b) => b.lastUpdated.toMillis() - a.lastUpdated.toMillis());
          const sessionId = sessions[sessions.length - 1].id
          router.push(`/${sessionId}`);
        } else {
          const sessionId = uuidv4();
          router.push(`/${sessionId}`);
        }
      });
    } else {
      // console.log("Login")
      router.push('/login');
    }
  });

  return (
    <div>
      <Loader message="Beginning login processs..." />
    </div>
  );
}
