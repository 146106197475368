import '../styles/loader.scss';

export default function Loader(props: { message: string }) {
  if (!props.message) {
    return <></>;
  }
  console.debug(`Loader message: ${props.message}`);

  return (
    <div className="loader">
      <div className="background"></div>
      <div className="center">
        <div className="spinner" />
        <p className="message">{props.message}</p>
      </div>
    </div>
  );
}
