import * as userSessionRepo from '@/repositories/user-session-repo';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import AWSAppSyncClient from 'aws-appsync';
import { config } from "../config"

export const getAPIClient = () => {
  const apiHttpLink = createHttpLink({
    uri: config.apiUrl(),
  });

  // Get the authentication token from local storage if it exists.
  const token = userSessionRepo.getAccessToken();

  const apiAuthLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: apiAuthLink.concat(apiHttpLink),
    cache: new InMemoryCache(),
  });

  return client;
};

export const getWSClient = () => {
  const client = new AWSAppSyncClient({
    url: config.apiUrl(),
    region: config.region(),
    auth: {
      type: 'AMAZON_COGNITO_USER_POOLS',
      jwtToken: userSessionRepo.getAccessToken()!,
    },
    disableOffline: true,
  });

  return client;
};
